import locale770bf1f0 from '../../lang/ar.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ar","numberFormats":{"ar":{"currency":{"style":"currency","currency":"USD","currencyDisplay":"symbol"}},"kw":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"ar","label":"المملكة العربية السعودية","file":"ar.js","iso":"ar"},{"code":"kw","label":"الكويت","file":"kw.js","iso":"kw"}],
  defaultLocale: "ar",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"vsf-locale","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  currency: "USD",
  country: "ar-sa",
  currencies: [{"name":"EUR","label":"Euro"},{"name":"USD","label":"Dollar"}],
  seo: true,
  useCookie: true,
  cookieKey: "language",
  normalizedLocales: [{"code":"ar","label":"المملكة العربية السعودية","file":"ar.js","iso":"ar"},{"code":"kw","label":"الكويت","file":"kw.js","iso":"kw"}],
  localeCodes: ["ar","kw"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "a",
  3: "r",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "a",
  19: "r",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "k",
  27: "w",
  28: ".",
  29: "j",
  30: "s",
  31: "\"",
  32: ":",
  33: "\"",
  34: ".",
  35: ".",
  36: "/",
  37: "l",
  38: "a",
  39: "n",
  40: "g",
  41: "/",
  42: "k",
  43: "w",
  44: ".",
  45: "j",
  46: "s",
  47: "\"",
  48: "}",
}

export const localeMessages = {
  'ar.js': () => Promise.resolve(locale770bf1f0),
  'kw.js': () => import('../../lang/kw.js' /* webpackChunkName: "lang-kw.js" */),
}
