import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _634ce496 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0faef30f = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _046e1618 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _98dd6490 = () => interopDefault(import('../pages/delete-account/index.vue' /* webpackChunkName: "pages/delete-account/index" */))
const _61a07dca = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _ed15ae2c = () => interopDefault(import('../pages/features/index.vue' /* webpackChunkName: "pages/features/index" */))
const _e5b48e60 = () => interopDefault(import('../pages/full-store/index.vue' /* webpackChunkName: "pages/full-store/index" */))
const _0a946cbb = () => interopDefault(import('../pages/instagram/index.vue' /* webpackChunkName: "pages/instagram/index" */))
const _55b03014 = () => interopDefault(import('../pages/matjrah-vs-salla-zid/index.vue' /* webpackChunkName: "pages/matjrah-vs-salla-zid/index" */))
const _5cfd1f5d = () => interopDefault(import('../pages/pos/index.vue' /* webpackChunkName: "pages/pos/index" */))
const _660e1d37 = () => interopDefault(import('../pages/prices/index.vue' /* webpackChunkName: "pages/prices/index" */))
const _21ff39c4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _0a8303fe = () => interopDefault(import('../pages/sign-in/index.vue' /* webpackChunkName: "pages/sign-in/index" */))
const _debfac18 = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _3f6dac7e = () => interopDefault(import('../pages/store-app/index.vue' /* webpackChunkName: "pages/store-app/index" */))
const _2f2cf261 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliate",
    component: _634ce496,
    name: "affiliate___ar___default"
  }, {
    path: "/ar",
    component: _0547af50,
    name: "index___ar"
  }, {
    path: "/blog",
    component: _0faef30f,
    name: "blog___ar___default"
  }, {
    path: "/contact-us",
    component: _046e1618,
    name: "contact-us___ar___default"
  }, {
    path: "/delete-account",
    component: _98dd6490,
    name: "delete-account___ar___default"
  }, {
    path: "/faqs",
    component: _61a07dca,
    name: "faqs___ar___default"
  }, {
    path: "/features",
    component: _ed15ae2c,
    name: "features___ar___default"
  }, {
    path: "/full-store",
    component: _e5b48e60,
    name: "full-store___ar___default"
  }, {
    path: "/instagram",
    component: _0a946cbb,
    name: "instagram___ar___default"
  }, {
    path: "/kw",
    component: _0547af50,
    name: "index___kw"
  }, {
    path: "/matjrah-vs-salla-zid",
    component: _55b03014,
    name: "matjrah-vs-salla-zid___ar___default"
  }, {
    path: "/pos",
    component: _5cfd1f5d,
    name: "pos___ar___default"
  }, {
    path: "/prices",
    component: _660e1d37,
    name: "prices___ar___default"
  }, {
    path: "/privacy-policy",
    component: _21ff39c4,
    name: "privacy-policy___ar___default"
  }, {
    path: "/sign-in",
    component: _0a8303fe,
    name: "sign-in___ar___default"
  }, {
    path: "/sign-up",
    component: _debfac18,
    name: "sign-up___ar___default"
  }, {
    path: "/store-app",
    component: _3f6dac7e,
    name: "store-app___ar___default"
  }, {
    path: "/terms-and-conditions",
    component: _2f2cf261,
    name: "terms-and-conditions___ar___default"
  }, {
    path: "/ar/affiliate",
    component: _634ce496,
    name: "affiliate___ar"
  }, {
    path: "/ar/blog",
    component: _0faef30f,
    name: "blog___ar"
  }, {
    path: "/ar/contact-us",
    component: _046e1618,
    name: "contact-us___ar"
  }, {
    path: "/ar/delete-account",
    component: _98dd6490,
    name: "delete-account___ar"
  }, {
    path: "/ar/faqs",
    component: _61a07dca,
    name: "faqs___ar"
  }, {
    path: "/ar/features",
    component: _ed15ae2c,
    name: "features___ar"
  }, {
    path: "/ar/full-store",
    component: _e5b48e60,
    name: "full-store___ar"
  }, {
    path: "/ar/instagram",
    component: _0a946cbb,
    name: "instagram___ar"
  }, {
    path: "/ar/matjrah-vs-salla-zid",
    component: _55b03014,
    name: "matjrah-vs-salla-zid___ar"
  }, {
    path: "/ar/pos",
    component: _5cfd1f5d,
    name: "pos___ar"
  }, {
    path: "/ar/prices",
    component: _660e1d37,
    name: "prices___ar"
  }, {
    path: "/ar/privacy-policy",
    component: _21ff39c4,
    name: "privacy-policy___ar"
  }, {
    path: "/ar/sign-in",
    component: _0a8303fe,
    name: "sign-in___ar"
  }, {
    path: "/ar/sign-up",
    component: _debfac18,
    name: "sign-up___ar"
  }, {
    path: "/ar/store-app",
    component: _3f6dac7e,
    name: "store-app___ar"
  }, {
    path: "/ar/terms-and-conditions",
    component: _2f2cf261,
    name: "terms-and-conditions___ar"
  }, {
    path: "/kw/affiliate",
    component: _634ce496,
    name: "affiliate___kw"
  }, {
    path: "/kw/blog",
    component: _0faef30f,
    name: "blog___kw"
  }, {
    path: "/kw/contact-us",
    component: _046e1618,
    name: "contact-us___kw"
  }, {
    path: "/kw/delete-account",
    component: _98dd6490,
    name: "delete-account___kw"
  }, {
    path: "/kw/faqs",
    component: _61a07dca,
    name: "faqs___kw"
  }, {
    path: "/kw/features",
    component: _ed15ae2c,
    name: "features___kw"
  }, {
    path: "/kw/full-store",
    component: _e5b48e60,
    name: "full-store___kw"
  }, {
    path: "/kw/instagram",
    component: _0a946cbb,
    name: "instagram___kw"
  }, {
    path: "/kw/matjrah-vs-salla-zid",
    component: _55b03014,
    name: "matjrah-vs-salla-zid___kw"
  }, {
    path: "/kw/pos",
    component: _5cfd1f5d,
    name: "pos___kw"
  }, {
    path: "/kw/prices",
    component: _660e1d37,
    name: "prices___kw"
  }, {
    path: "/kw/privacy-policy",
    component: _21ff39c4,
    name: "privacy-policy___kw"
  }, {
    path: "/kw/sign-in",
    component: _0a8303fe,
    name: "sign-in___kw"
  }, {
    path: "/kw/sign-up",
    component: _debfac18,
    name: "sign-up___kw"
  }, {
    path: "/kw/store-app",
    component: _3f6dac7e,
    name: "store-app___kw"
  }, {
    path: "/kw/terms-and-conditions",
    component: _2f2cf261,
    name: "terms-and-conditions___kw"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___ar___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
